import React, { useState, useEffect } from 'react';
import { X, Loader } from 'lucide-react';
import config from '../config';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import NotifyModal from './NotifyModal';
import { useNavigate } from 'react-router-dom';

const DEV_MODE = config.DEV_MODE === 'true';


const updateUserLocation = async (userId, UUID, facilities) => {
  const apiEndpoint = DEV_MODE
    ? 'http://localhost:8000/user_location'
    : 'https://api.app.kita.kids/user_location';

  try {
    const response = await axios.post(`${apiEndpoint}/${userId}/${UUID}`, 
      facilities
    , {
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': config.KITA_API_KEY
      }
    });
    if (response.status === 200) {
      console.log('Facilities updated successfully');
      // Update cookie with new progress
      const cookieData = Cookies.get('formSubmissionData');
      if (cookieData) {
        const updatedCookieData = JSON.parse(cookieData);
        updatedCookieData.kitasSelected = facilities.length > 0;
        Cookies.set('formSubmissionData', JSON.stringify(updatedCookieData));
      }
      // Update selectedLocations cookie
      Cookies.set('selectedLocations', JSON.stringify(facilities), { expires: 30 });
    }
  } catch (error) {
    console.error('Error updating facilities:', error);
  }
};

const Modal = ({ isOpen, onClose, onConfirm, message, disableContinue, onAdjustSelection, onContinueWithTrim, continueWithTrimText }) => {
  const { t } = useTranslation();

  // Add this line somewhere in your component or in your translation file
  t('packages.waitlistSuccess', 'Your entry to the waitlist was successful. We will notify you when capacity becomes available.');
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">{t('packages.confirmationRequired')}</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        <p className="mb-4">{message}</p>
        <p className="mb-6">{t('packages.pleaseCheckTerms')} <a href="https://www.kita.kids/imprint-agb" className="text-[#9151D2] hover:underline">{t('packages.termsAndConditions')}</a>.</p>
        <div className="flex justify-end space-x-4">
          {disableContinue ? (
            <>
              <button
                onClick={onAdjustSelection}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition duration-300"
              >
                {t('packages.adjustSelection')}
              </button>
              <button
                onClick={onContinueWithTrim}
                className="px-4 py-2 bg-[#9151D2] text-white rounded hover:bg-[#7b3ab5] transition duration-300"
              >
                {continueWithTrimText}
              </button>
            </>
          ) : (
            <>
              <button
                onClick={onClose}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition duration-300"
              >
                {t('packages.cancel')}
              </button>
              <button
                onClick={onConfirm}
                className="px-4 py-2 bg-[#9151D2] text-white rounded hover:bg-[#7b3ab5] transition duration-300"
              >
                {t('packages.continue')}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const PackageCard = ({ title, description, price, originalPrice, features, highlight, packageId, locationCount, selectedFacilitiesCount, paymentStatus, isBenefitsMePartner, hasCapacity, isVoiioPartner, isNew }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isNotifyModalOpen, setIsNotifyModalOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const selectedLocations = JSON.parse(Cookies.get('selectedLocations') || '[]');
  const facilitiesCount = selectedLocations.length;

  const cardContentStyle = {
    opacity: hasCapacity === false && !isVoiioPartner && !isBenefitsMePartner ? 0.5 : 1,
    transition: 'opacity 0.3s ease-in-out',
  };

  const handleClick = async (e) => {
    e.preventDefault();
    const formSubmissionData = JSON.parse(Cookies.get('formSubmissionData') || '{}');
    const selectedLocations = JSON.parse(Cookies.get('selectedLocations') || '[]');

    if (title === t('packages.firstStep.title') && hasCapacity === false && !isVoiioPartner && !isBenefitsMePartner) {
      setIsNotifyModalOpen(true);
    } else if (formSubmissionData && formSubmissionData.UUID) {
      if (selectedLocations.length !== locationCount) {
        setIsModalOpen(true);
      } else {
        setIsLoading(true);
        await redirectToShop(formSubmissionData.UUID);
      }
    } else {
      // Redirect to the form page when no UUID is present
      window.location.href = 'https://app.kita.kids/form';
    }
  };

  const redirectToShop = async (UUID) => {
    const formSubmissionData = JSON.parse(Cookies.get('formSubmissionData') || '{}');
    const selectedLocations = JSON.parse(Cookies.get('selectedLocations') || '[]');
    

    if (formSubmissionData.UUID && formSubmissionData.userId) {
      await updateUserLocation(formSubmissionData.userId, formSubmissionData.UUID, selectedLocations);
    } else {
      console.error('UUID or userId not found in formSubmissionData cookie');
    }

    const apiEndpoint = DEV_MODE
      ? 'http://localhost:8000/redirectToShop'
      : `${config.API_BASE_URL}/redirectToShop`;
    window.location.href = `${apiEndpoint}?UUID=${UUID}&package=${packageId}`;
  };

  const handleConfirm = async () => {
    setIsModalOpen(false);
    setIsLoading(true);
    const formSubmissionData = JSON.parse(Cookies.get('formSubmissionData') || '{}');
    await redirectToShop(formSubmissionData.UUID);
  };

  const handleAdjustSelection = () => {
    setIsModalOpen(false);
    const findElement = document.querySelector('[data-testid="find-kitas-todo"]');
    if (findElement) {
      findElement.scrollIntoView({ behavior: 'smooth' });
      // Check if the Find component is not already visible
      const findComponent = document.querySelector('[data-testid="find-component"]');
      if (!findComponent || findComponent.style.display === 'none') {
        findElement.click();
      }
    }
    
    // Ensure the Find component is visible and scroll to it
    setTimeout(() => {
      const findComponent = document.querySelector('[data-testid="find-component"]');
      if (findComponent) {
        findComponent.style.display = 'block';
        findComponent.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
    
    const formSubmissionData = JSON.parse(Cookies.get('formSubmissionData') || '{}');
    formSubmissionData.kitasSelected = false;
    Cookies.set('formSubmissionData', JSON.stringify(formSubmissionData));
  };

  const handleContinueWithTrim = async () => {
    setIsModalOpen(false);
    setIsLoading(true);
    const selectedLocationsArray = JSON.parse(Cookies.get('selectedLocations') || '[]');
    const trimmedLocations = selectedLocationsArray.slice(0, locationCount);
    Cookies.set('selectedLocations', JSON.stringify(trimmedLocations));
    const formSubmissionData = JSON.parse(Cookies.get('formSubmissionData') || '{}');
    
    if (formSubmissionData.UUID && formSubmissionData.userId) {
      await updateUserLocation(formSubmissionData.userId, formSubmissionData.UUID, trimmedLocations);
    } else {
      console.error('UUID or userId not found in formSubmissionData cookie');
    }

    redirectToShop(formSubmissionData.UUID);
  };

  const getModalMessage = () => {
    if (facilitiesCount > locationCount) {
      return t('packages.tooManyLocations', { selected: facilitiesCount, available: locationCount }) + ' ' + t('packages.continueWithTrimMessage', { count: locationCount });
    } else if (facilitiesCount < locationCount) {
      return t('packages.fewerLocations', { selected: facilitiesCount, available: locationCount });
    }
    return t('packages.confirmSelection');
  };

  return (
    <>
      <div className={`bg-gray-100 p-6 rounded-lg shadow-lg flex flex-col h-full transition-all duration-300 
        ${highlight ? 'border-2 border-[#9151D2] transform hover:scale-105' : 'hover:shadow-xl'}
        ${isNew ? 'border-2 border-red-500' : ''}`}>
        <div style={cardContentStyle} className="flex-grow flex flex-col relative">
          {isNew && (
            <div className="absolute -top-3 -right-3 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-full transform rotate-12">
              {t('new')}
            </div>
          )}
          {highlight && <span className="bg-[#9151D2] text-white text-sm font-semibold px-3 py-1 rounded-full self-start mb-2">{highlight}</span>}
          <h3 className="text-2xl font-bold mb-2 text-[#020617]">{title}</h3>
          <p className="text-gray-700 mb-4">{description}</p>
          <div className="mb-4">
            {isBenefitsMePartner ? (
              <div className="flex flex-col">
                <div className="flex items-center">
                  <p className="text-4xl font-bold text-[#9151D2]">€{price}</p>
                  <div className="ml-2 flex flex-col items-start">
                    <p className="text-lg line-through text-gray-500">€{originalPrice}</p>
                    <p className="text-sm font-semibold text-green-600">{t('packages.benefitsMeAdvantage')}</p>
                  </div>
                </div>
                <span className="text-sm font-normal text-gray-700 mt-1">{t('packages.oneTimeIncVAT')}</span>
              </div>
            ) : (
              <div className="flex flex-col">
                <p className="text-4xl font-bold text-[#9151D2]">€{price}</p>
                <span className="text-sm font-normal text-gray-700 mt-1">{t('packages.oneTimeIncVAT')}</span>
              </div>
            )}
          </div>
          <ul className="mb-6 flex-grow space-y-2">
            {features.map((feature, index) => (
              <li key={index} className="flex items-start">
                <svg className="w-5 h-5 mr-2 text-[#24D0BD] flex-shrink-0 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                </svg>
                <span className="text-gray-700">{feature}</span>
              </li>
            ))}
          </ul>
        </div>
        {paymentStatus?.value !== 'paid' && (
          <div className="mt-auto">
            <button 
              onClick={handleClick}
              className={`font-semibold py-3 px-6 rounded-lg transition duration-300 text-lg inline-block text-center w-full ${
                hasCapacity === false && !isVoiioPartner && !isBenefitsMePartner ? 'bg-yellow-500 text-white hover:bg-yellow-600' : 'bg-[#9151D2] text-white hover:bg-[#7b3ab5]'
              }`}
              disabled={isLoading || (hasCapacity === null && !isVoiioPartner && !isBenefitsMePartner)}
            >
              {isLoading ? (
                <div className="flex items-center justify-center">
                  <Loader className="animate-spin h-5 w-5 mr-3" />
                  {t('packages.loading')}
                </div>
              ) : hasCapacity === false && !isVoiioPartner && !isBenefitsMePartner ? (
                t('packages.waitlistRegistration')
              ) : JSON.parse(Cookies.get('formSubmissionData') || '{}').UUID ? (
                t('packages.completeAndSecure')
              ) : (
                t('packages.fillQuestionnaire')
              )}
            </button>
          </div>
        )}
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirm}
        onAdjustSelection={handleAdjustSelection}
        onContinueWithTrim={handleContinueWithTrim}
        message={getModalMessage()}
        disableContinue={facilitiesCount !== locationCount}
        continueWithTrimText={facilitiesCount < locationCount ? t('packages.continue') : t('packages.continueWithTrim', { count: locationCount })}
      />
      <NotifyModal
        isOpen={isNotifyModalOpen}
        onClose={() => setIsNotifyModalOpen(false)}
        message={t('packages.waitlistSuccess')}
        packageName={title}
      />
    </>
  );
};

const Packages = ({ selectedFacilitiesCount = '', paymentStatus = null }) => {
  const { t } = useTranslation();
  const [isVoiioPartner, setIsVoiioPartner] = useState(false);
  const [isBenefitsMePartner, setIsBenefitsMePartner] = useState(false);
  const [hasCapacity, setHasCapacity] = useState(null);

  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem('formData') || '{}');
    const formSubmissionData = JSON.parse(Cookies.get('formSubmissionData') || '{}');
    
    const businessPartner = formData.businessPartner || formSubmissionData.businessPartner;

    if (businessPartner === 'voiio') {
      setIsVoiioPartner(true);
      setHasCapacity(true); // Always set capacity to true for business partners
    } else if (businessPartner === 'benefitsme') {
      setIsBenefitsMePartner(true);
      setHasCapacity(true); // Always set capacity to true for business partners
    } else {
      checkCapacity(); // Only check capacity if there's no business partner
    }
  }, []);

  const checkCapacity = async () => {
    try {
      const apiEndpoint = DEV_MODE
        ? 'http://localhost:8000/capacity'
        : `${config.API_BASE_URL}/capacity`;
      
      const response = await axios.get(apiEndpoint, {
        headers: {
          'X-API-Key': config.KITA_API_KEY
        }
      });
      setHasCapacity(response.data.hasCapacity);
    } catch (error) {
      console.error('Error checking capacity:', error);
      setHasCapacity(null); // Set to null in case of error
    }
  };

  const packages = isVoiioPartner
    ? [
        {
          title: "voiio",
          description: t('packages.highSpeed.description'),
          price: "0",
          originalPrice: "0",
          features: [
            t('packages.perfectImpression.feature3'),
            t('packages.highSpeed.feature2'),
            t('packages.highSpeed.feature3'),
            t('packages.highSpeed.feature4'),
          ],
          highlight: "voiio Partner",
          packageId: "48760397431126",
          locationCount: 15
        }
      ]
    : [
        {
          title: t('packages.giveMoreTitle'),
          description: t('packages.giveMoreDescription'),
          originalPrice: "598",
          features: [
            t('packages.giveMore.feature2'),
            t('packages.giveMore.feature1'),
            t('packages.giveMore.feature3'),
            t('packages.giveMore.feature4'),
            <span className="font-bold">{t('packages.giveMore.feature5')}</span>
          ],
          packageId: "47998846730582",
          locationCount: 20 
        }
      ];

  // Apply 10% discount for benefits.me partners
  const discountedPackages = packages.map(pkg => ({
    ...pkg,
    price: isBenefitsMePartner ? (pkg.originalPrice * 0.9).toFixed(2) : pkg.originalPrice
  }));

  return (
    <div className="packages py-20 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        {discountedPackages.length > 1 && (
          <>
            <h3 className="text-3xl font-bold text-center mb-6 text-[#020617]">{t('packages.ourPackages')}</h3>
            <p className="text-center mb-12 text-gray-600">
              {t('packages.firstStep.subtitle')}
            </p>
          </>
        )}
        <div className={`grid ${discountedPackages.length === 1 ? 'grid-cols-1 max-w-md mx-auto' : 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3'} gap-8`}>
          {discountedPackages.filter(Boolean).map((pkg, index) => (
            <PackageCard 
              key={index} 
              {...pkg} 
              selectedFacilitiesCount={selectedFacilitiesCount} 
              paymentStatus={paymentStatus}
              isBenefitsMePartner={isBenefitsMePartner}
              isVoiioPartner={isVoiioPartner}
              hasCapacity={pkg.title === t('packages.firstStep.title') ? hasCapacity : true}
              isNew={pkg.isNew}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Packages;
