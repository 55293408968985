import React, { useState, useRef, useEffect, forwardRef } from 'react';
import { FaUser, FaEnvelope, FaPhone, FaBuilding, FaChild, FaCalendarAlt, FaClock, FaBus, FaSpinner, FaCheckCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import config from '../config';
import LocationModal from './LocationModal';
import { useSearchParams } from 'react-router-dom';

const API_BASE_URL = config.API_BASE_URL;
const KITA_API_KEY = config.KITA_API_KEY;
const DEV_MODE = config.DEV_MODE === 'true';

const apiEndpoint = DEV_MODE
  ? 'http://localhost:8000'
  : 'https://api.app.kita.kids';

const scrollToRef = (ref) => {
  if (ref && ref.current) {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};

const StepIndicator = React.forwardRef(({ currentStep, onStepClick }, ref) => {
  const { t } = useTranslation();
  const steps = [t('kitaPartnerForm.step1'), t('kitaPartnerForm.step2')];

  return (
    <div ref={ref} className="flex flex-wrap justify-between items-center mb-8">
      {steps.map((step, index) => (
        <div 
          key={step} 
          className="flex flex-col items-center mb-4 cursor-pointer"
          onClick={() => onStepClick(index + 1)}
        >
          <div className={`w-8 h-8 rounded-full flex items-center justify-center ${
            index + 1 === currentStep ? 'bg-[#9151D2] text-white' : 'bg-gray-200 text-gray-600'
          }`}>
            {index + 1}
          </div>
          <span className="mt-2 text-xs sm:text-sm text-center">{step}</span>
        </div>
      ))}
    </div>
  );
});

const KitaPartnerForm = () => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(1);
  const stepIndicatorRef = useRef(null);

  const [searchParams, setSearchParams] = useSearchParams();
  
  useEffect(() => {
    scrollToRef(stepIndicatorRef);
  }, [currentStep]);

  const [isLoading, setIsLoading] = useState(false);



  const formatTime = (time) => {
    if (!time) return '';
    const [hours, minutes] = time.split(':');
    return `${hours}:${minutes.padStart(2, '0')}`;
  };
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [formData, setFormData] = useState({
    contactPerson: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
    },
    locations: [],
    acceptPrivacyPolicy: false,
    acceptTerms: false,
  });

  const [showModal, setShowModal] = useState(false);
  const [currentLocationIndex, setCurrentLocationIndex] = useState(null);

  const handleStepClick = (step) => {
    setCurrentStep(step);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      contactPerson: {
        ...prevState.contactPerson,
        [name]: type === 'checkbox' ? checked : value
      }
    }));
  };

  const addLocation = () => {
    setCurrentLocationIndex(formData.locations.length);
    setShowModal(true);
  };

  const editLocation = (index) => {
    setCurrentLocationIndex(index);
    setShowModal(true);
  };

  const removeLocation = (index) => {
    setFormData(prevState => ({
      ...prevState,
      locations: prevState.locations.filter((_, i) => i !== index)
    }));
  };

  const handleLocationSave = (locationData) => {
    setFormData(prevState => {
      const newLocations = [...prevState.locations];
      if (currentLocationIndex === newLocations.length) {
        newLocations.push(locationData);
      } else {
        newLocations[currentLocationIndex] = locationData;
      }
      return {
        ...prevState,
        locations: newLocations
      };
    });
    setShowModal(false);
  };

  const isStepValid = () => {
    switch (currentStep) {
      case 1:
        return formData.contactPerson.firstName.trim() !== '' && 
               formData.contactPerson.lastName.trim() !== '' && 
               formData.contactPerson.phone.trim() !== '' && 
               formData.contactPerson.email.trim() !== '';
      case 2:
        return formData.locations.length > 0 && 
               formData.acceptPrivacyPolicy && 
               formData.acceptTerms;
      default:
        return false;
    }
  };

  const redirect = (newFacilities) => {
    const redirectUrl = searchParams.get('redirectUrl');
    if (!redirectUrl) return;
    if (redirectUrl.includes('/einrichtungs-auswahl')) {
      window.location.href = redirectUrl.split('?')[0] + '?search=' + encodeURI(newFacilities.name);
    } else {
      window.location.href = redirectUrl;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isStepValid()) {
      console.log('Form is not valid');
      return;
    }

    setIsLoading(true);

    try {
      // First, submit the contact information
      const contactResponse = await axios.post(`${apiEndpoint}/facilityContactSubmission`, {
        name: `${formData.contactPerson.firstName} ${formData.contactPerson.lastName}`,
        Notes: "",
        Active: true,
        phoneNumber: formData.contactPerson.phone,
        email: formData.contactPerson.email,
        facility: []
      }, {
        headers: {
          'Content-Type': 'application/json',
          'X-API-Key': KITA_API_KEY
        }
      });

      if (contactResponse.status !== 200 || !contactResponse.data || !contactResponse.data.id) {
        throw new Error('Failed to submit contact information');
      }

      const contactId = contactResponse.data.id;

      // Then, submit each facility
      for (const location of formData.locations) {
        const facilityData = {};

        if (location.kitaName) facilityData.name = location.kitaName;
        if (formData.contactPerson.firstName) facilityData.firstname = formData.contactPerson.firstName;
        if (formData.contactPerson.lastName) facilityData.lastname = formData.contactPerson.lastName;
        if (location.address.street && location.address.houseNumber) facilityData.address = `${location.address.street} ${location.address.houseNumber}`;
        if (location.address.postalCode) facilityData.postcode = location.address.postalCode;
        if (location.address.city) facilityData.city = location.address.city;
        if (location.email) facilityData.email = location.email;
        if (location.phone) facilityData.phone = location.phone;
        if (location.website) facilityData.website = location.website;
        if (location.minimumAge) facilityData.mindestalter = parseFloat(location.minimumAge);
        if (location.careTimeStart) facilityData.betreuungsbeginn = formatTime(location.careTimeStart);
        if (location.careTimeEnd) facilityData.betreuungsende = formatTime(location.careTimeEnd);
        if (location.childrenCount) facilityData.maxanzahlKinder = parseInt(location.childrenCount);
        if (location.pedagogicalConcept) facilityData.paedagogischesKonzeptText = location.pedagogicalConcept;
        if (location.hasCommunalFeeLimit !== undefined) facilityData.kommunaleGebuehrengrenze = location.hasCommunalFeeLimit;
        if (location.cateringOption) {
          switch (location.cateringOption) {
            case 'inHouse':
              facilityData.verpflegungsoption = 3122;
              break;
            case 'external':
              facilityData.verpflegungsoption = 3123;
              break;
            case 'none':
              facilityData.verpflegungsoption = 3124;
              break;
            default:
              facilityData.verpflegungsoption = null;
          }
        }
        if (location.isAccessible !== undefined) facilityData.barrierefrei = location.isAccessible;
        if (location.hasOutdoorArea !== undefined) facilityData.aussenbereich = location.hasOutdoorArea;
        if (location.closingDays) facilityData.schliesstage = location.closingDays;
        if (location.additionalServices) facilityData.zusaetzlicheDienstleistungen = location.additionalServices;
        if (contactId) facilityData.managePerson = [contactId.toString()];
        if (location.organizationType) facilityData.organisationstyp = location.organizationType;
        if (location.organizationType === 'private') facilityData.isPrivat = true;
        if (location.organizationName) facilityData.nameTraeger = location.organizationName;

        await axios.post(`${apiEndpoint}/facilitysubmission`, facilityData, {
          headers: {
            'Content-Type': 'application/json',
            'X-API-Key': KITA_API_KEY
          }
        });
      }

      console.log('All submissions successful');
      setShowSuccessModal(true);
      setTimeout(() => {
        redirect({ name: formData.locations[0].kitaName });
        setShowSuccessModal(false);
        // Reset form or perform any other necessary actions
        setFormData({
          contactPerson: {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
          },
          locations: [],
          acceptPrivacyPolicy: false,
          acceptTerms: false,
        });
        setCurrentStep(1);
      }, 3000); // Close the modal after 3 seconds
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const SuccessModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center">
        <FaCheckCircle className="text-green-500 text-5xl mb-4 mx-auto" />
        <h2 className="text-2xl font-bold mb-4">{t('kitaPartnerFormSuccess.successTitle')}</h2>
        <p>{t('kitaPartnerFormSuccess.successMessage')}</p>
      </div>
    </div>
  );

  return (
    <div className="max-w-4xl mx-auto p-4 sm:p-8 mt-16">
      <div className="mx-auto mb-6 w-full max-w-[400px] md:max-w-[350px] lg:max-w-[300px] rounded-lg overflow-hidden shadow-md">
        <img src={`${process.env.PUBLIC_URL}/partnerkita.webp`} alt={t('kitaPartnerForm.partnerKitaAlt')} className="w-full h-auto" />
      </div>
      <h2 className="text-3xl font-bold text-[#9151D2] mb-6 text-center">{t('kitaPartnerForm.title')}</h2>
      <StepIndicator ref={stepIndicatorRef} currentStep={currentStep} onStepClick={handleStepClick} />
      <form onSubmit={handleSubmit} className="space-y-6">
        {currentStep === 1 && (
          <section className="bg-gray-50 p-6 rounded-lg mb-6">
            <h2 className="text-xl font-semibold mb-4">{t('kitaPartnerForm.contactPersonInfo')}</h2>
            <p className="text-gray-600 mb-4">{t('kitaPartnerForm.contactPersonDescription')}</p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="relative">
                <label htmlFor="firstName" className="block mb-2 text-lg text-gray-700">{t('kitaPartnerForm.contactPersonFirstName')}</label>
                <div className="relative">
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={formData.contactPerson.firstName}
                    onChange={handleInputChange}
                    className="w-full p-3 pl-10 text-lg border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#9151d2]"
                    required
                    placeholder={t('kitaPartnerForm.contactPersonFirstName')}
                  />
                  <FaUser className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
                </div>
              </div>
              <div className="relative">
                <label htmlFor="lastName" className="block mb-2 text-lg text-gray-700">{t('kitaPartnerForm.contactPersonLastName')}</label>
                <div className="relative">
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formData.contactPerson.lastName}
                    onChange={handleInputChange}
                    className="w-full p-3 pl-10 text-lg border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#9151d2]"
                    required
                    placeholder={t('kitaPartnerForm.contactPersonLastName')}
                  />
                  <FaUser className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
                </div>
              </div>
              <div className="relative">
                <label htmlFor="phone" className="block mb-2 text-lg text-gray-700">{t('kitaPartnerForm.contactPersonPhone')}</label>
                <div className="relative">
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.contactPerson.phone}
                    onChange={handleInputChange}
                    className="w-full p-3 pl-10 text-lg border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#9151d2]"
                    required
                    placeholder={t('kitaPartnerForm.contactPersonPhone')}
                  />
                  <FaPhone className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
                </div>
              </div>
              <div className="relative">
                <label htmlFor="email" className="block mb-2 text-lg text-gray-700">{t('kitaPartnerForm.contactPersonEmail')}</label>
                <div className="relative">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.contactPerson.email}
                    onChange={handleInputChange}
                    className="w-full p-3 pl-10 text-lg border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#9151d2]"
                    required
                    placeholder={t('kitaPartnerForm.contactPersonEmail')}
                  />
                  <FaEnvelope className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
                </div>
              </div>
            </div>
          </section>
        )}

        {currentStep === 2 && (
          <>
            <section className="bg-gray-50 p-6 rounded-lg mb-6">
              <h2 className="text-xl font-semibold mb-4">{t('kitaPartnerForm.locations')}</h2>
              {formData.locations.map((location, index) => (
                <div key={index} className="mb-4 p-4 border rounded-lg">
                  <h3 className="text-lg font-semibold">{location.kitaName}</h3>
                  <p>{location.address.street}, {location.address.postalCode} {location.address.city}</p>
                  <div className="mt-2">
                    <button
                      type="button"
                      onClick={() => editLocation(index)}
                      className="mr-2 px-3 py-1 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300"
                    >
                      {t('kitaPartnerForm.edit')}
                    </button>
                    <button
                      type="button"
                      onClick={() => removeLocation(index)}
                      className="px-3 py-1 bg-red-500 text-white rounded-lg hover:bg-red-600 transition duration-300"
                    >
                      {t('kitaPartnerForm.remove')}
                    </button>
                  </div>
                </div>
              ))}
              <button
                type="button"
                onClick={addLocation}
                className="mt-4 px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition duration-300"
              >
                {t('kitaPartnerForm.addLocation')}
              </button>
            </section>

            <div className="flex flex-col space-y-4 mt-6">
              <div className="mb-4">
                <label className="flex items-center text-gray-700 text-sm cursor-pointer">
                  <input
                    type="checkbox"
                    name="acceptPrivacyPolicy"
                    checked={formData.acceptPrivacyPolicy}
                    onChange={(e) => setFormData(prev => ({ ...prev, acceptPrivacyPolicy: e.target.checked }))}
                    className="form-checkbox h-5 w-5 text-[#9151d2]"
                    required
                  />
                  <span className="ml-2">
                    {t('kitaPartnerForm.privacyPolicyConfirmation')} <a href="https://www.kita.kids/privacy" className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">{t('kitaPartnerForm.privacyPolicy')}</a>
                  </span>
                </label>
              </div>
              <div className="mb-4">
                <label className="flex items-center text-gray-700 text-sm cursor-pointer">
                  <input
                    type="checkbox"
                    name="acceptTerms"
                    checked={formData.acceptTerms}
                    onChange={(e) => setFormData(prev => ({ ...prev, acceptTerms: e.target.checked }))}
                    className="form-checkbox h-5 w-5 text-[#9151d2]"
                    required
                  />
                  <span className="ml-2">
                    {t('kitaPartnerForm.termsAcceptance')} <a href="https://www.kita.kids/imprint-agb" className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">{t('kitaPartnerForm.terms')}</a>
                  </span>
                </label>
              </div>
            </div>
          </>
        )}

        <div className="flex flex-col space-y-4">
          <div className="flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
            {currentStep > 1 && (
              <button 
                type="button"
                onClick={() => setCurrentStep(currentStep - 1)}
                className="px-6 py-3 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition duration-300 text-lg font-semibold"
              >
                {t('kitaPartnerForm.back')}
              </button>
            )}
            <button 
              type="button"
              onClick={() => {
                if (currentStep < 2 && isStepValid()) {
                  setCurrentStep(currentStep + 1);
                } else if (currentStep === 2 && isStepValid()) {
                  handleSubmit(new Event('submit'));
                }
              }}
              className={`px-6 py-3 bg-[#9151d2] hover:bg-[#7b3ab5] text-white rounded-lg transition duration-300 text-lg font-semibold ${currentStep > 1 ? 'sm:ml-4' : 'w-full'} ${!isStepValid() || isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={!isStepValid() || isLoading}
            >
              {isLoading ? (
                <FaSpinner className="animate-spin inline-block mr-2" />
              ) : null}
              {currentStep === 2 ? (isLoading ? t('kitaPartnerForm.sending') : t('kitaPartnerForm.submit')) : t('kitaPartnerForm.next')}
            </button>
          </div>
        </div>
      </form>

      {showModal && (
        <LocationModal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          onSave={handleLocationSave}
          initialData={currentLocationIndex !== null ? formData.locations[currentLocationIndex] : null}
        />
      )}
      {showSuccessModal && <SuccessModal />}
    </div>
  );
};

export default KitaPartnerForm;
